import { envs } from "./envs";

export const EVENT_PAGE_URL = "https://lp.wanpass.me/event";

export const BUSINESS_REGISTER_FORM_DOM_ID = "register-form";

const STATIC_BASE = "https://wanpass.me"; // See `static` directory (../../static/). It will be deployed to host top level.
export const STATIC_OG_DEFAULT = STATIC_BASE + "/og/og-default.png";
export const STATIC_OG_BUSINESS_LP = STATIC_BASE + "/og/og-business-lp.png";
export const YOUTUBE = {
  BUSINESS_INTRODUCTION: "https://www.youtube.com/embed/UkuhwCLYIuA",
};

const DEFAULT_TRACKING_ID = "lp_button";

const ENTRY_BASE =
  envs.FLAVOR === "production"
    ? "https://entry.wanpass.me"
    : "https://dev.entry.wanpass.me";

export const getAppUrls = (trackingId_?: string) => {
  const trackingId = trackingId_ || DEFAULT_TRACKING_ID;
  return {
    ios: `${ENTRY_BASE}/install/ios/${trackingId}`,
    android: `${ENTRY_BASE}/install/android/${trackingId}`,
    common: `${ENTRY_BASE}/install/${trackingId}`,
  };
};

export const getQrImageUrl = (urlOrigin: string, { size = 150 } = {}) =>
  `https://api.qrserver.com/v1/create-qr-code/?size=${size}x${size}&margin=10&data=${encodeURIComponent(
    urlOrigin
  )}`;

export const getCheckinUrl = (qrCodeId: string) =>
  `${ENTRY_BASE}/checkin/${qrCodeId}`;

/**
 * canonical の URLを作成。path指定でもOK.
 */
export const getCanonicalUrl = (urlOrPath: string) => {
  if (urlOrPath.startsWith("/")) return `${STATIC_BASE}${urlOrPath}`;
  return urlOrPath;
};

/**
 * 法関係のリンク。
 */
export const getLawLinks = (): Record<
  "term" | "privacy" | "tokusho" | "help",
  [string, string]
> => {
  return {
    term: ["利用規約", `${ENTRY_BASE}/term`],
    privacy: ["プライバシーポリシー", `${ENTRY_BASE}/privacy`],
    tokusho: ["特定商取引法", `${ENTRY_BASE}/tokusho`],
    help: ["よくあるご質問", `${ENTRY_BASE}/help`],
  };
};
